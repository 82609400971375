import React from "react";
import { Control, UseFormMethods } from "react-hook-form";

export interface TermsConditionsProps {
  checkTerms: boolean;
  onTermsChecked: Function;
  hookProps: {
    errors: UseFormMethods["errors"];
    register: UseFormMethods["register"];
  };
  control: Control;
  onFieldFocus: Function;
  clearErrors: UseFormMethods["clearErrors"];
  setValue: UseFormMethods["setValue"];
}
export const TermsConditions: React.FC<TermsConditionsProps> = () => {
  return <></>;
};
