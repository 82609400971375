import CoreConnector from "@nvs/gatsby-theme-leap-core/src/components/hoc/context/api/appsync/full-connector";
import { Source } from "../../../../../../../utils/source";

export default class FullEnrollmentConnector extends CoreConnector {
  protected getRequestBody() {
    return {
      meta: {
        id: this.sessionToken,
        source: Source.get(),
        extension: [
          {
            id: "lastUpdatedBy",
            url: "lastUpdatedBy",
            valueIdentifier: {
              value: this.sessionToken,
              type: { text: "Cognito" },
              assigner: { display: this.request?.product + " website" },
            },
          },
        ],
      },
      userSessionId: this.sessionToken,
      ...this.request,
    };
  }
}
