import React, { useContext } from "react";
import MenuSection from "../common/section";
import context from "@nvs/gatsby-theme-leap-core/src/components/hoc/context/product/context";
import PhoneCall from "./phone-call";
import BrandLogo from "@nvs/gatsby-theme-leap-core/src/components/hoc/components/logo/logo";
import { Link } from "@nvs/gatsby-theme-leap-core/src/components/hoc/components/link";

const EnrollmentHeaderMobile: React.FC = () => {
  const ctxt = useContext(context);

  const config = [
    [
      ctxt.json.pages.header.menu.mobile_prescribing,
      ctxt.json.pages.header.menu.mobile_healthcare,
    ],
  ];

  return (
    <header className="block lg:hidden">
      <div className="bg-gradient-to-r from-[#FDE047] to-[#EAB308] flex flex-1 flex-row justify-center py-3 text-font font-bold lg:hidden">
        <div className="flex text-xs">
          <MenuSection options={config[0]} />
        </div>
      </div>
      <div className="w-full flex items-center justify-between py-5 px-10">
        <div className="flex justify-left">
          <Link
            href="https://www.entresto.com"
            target="_blank"
            rel="noopener noreferrer"
            title="Entresto Logo"
          >
            <BrandLogo className="w-full [min-width:144px] [max-width:150px]" />
          </Link>
        </div>
        <div className="text-font text-xs">{ctxt.json.pages.header.usOnly}</div>
        {ctxt.json.params.phoneNumber && (
          <div className="items-center text-brand text-base ">
            <PhoneCall
              label={
                ctxt.json.params.brandPhoneDisplay ||
                ctxt.json.params.phoneNumber
              }
              phoneNumber={ctxt.json.params.phoneNumber}
              callCtaText={ctxt.json.pages.header.callCtaText}
            />
          </div>
        )}
      </div>
    </header>
  );
};

export default EnrollmentHeaderMobile;
