import React from "react";
import PhoneIcon from "@nvs/gatsby-theme-leap-core/src/components/hoc/components/phoneCall/icon";
import { PhoneLink } from "@nvs/gatsby-theme-leap-core/src/components/hoc/components/link";
import { EventAction } from "@nvs/gatsby-theme-leap-core/src/services/ga";

const PhoneCall: React.FC<{
	label: string;
	phoneNumber: string;
	callCtaText?: string;
}> = ({ label, phoneNumber, callCtaText = '' }) => {
	return (
		<div className="text-brand font-semibold no-underline">
			<div className="flex flex-col items-center gap-1 lg:hidden">
				<PhoneIcon className="h-6 w-6 py-0" phoneNumber={phoneNumber} action={EventAction.CallUs} />
				<PhoneLink phoneNumber={phoneNumber} action={EventAction.CallUs}>
					{callCtaText || label}
				</PhoneLink>
			</div>
			<div className="hidden lg:block lg:flex">
				<PhoneIcon className="h-5 w-5 py-0" phoneNumber={phoneNumber} action={EventAction.CallUs} />
			</div>
		</div>
	);
};

export default PhoneCall;
