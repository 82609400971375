import React from "react";
import classNames from "classnames";
import { CardData } from "@nvs/gatsby-theme-leap-core/src/components/domains/confirmation/components/services/card/index";
import { Service } from "@nvs/gatsby-theme-leap-core/src/components/hoc/context/product/context-model";
import RichText from "@nvs/gatsby-theme-leap-core/src/components/hoc/components/rich-text";

const CardLeft: React.FC<{
  service: Service;
  data: CardData;
  printMode?: boolean;
}> = ({ service, printMode = false }) => {
  return (
    <div
      className={classNames(
        "rounded-bl-2xl rounded-tl-2xl flex justify-center items-center px-4 md:px-16 print:px-16",
        {
          "bg-cta shadow-md py-5": !printMode,
          "shadow-none": printMode,
        }
      )}
    >
      <div
        className={classNames('flex flex-col print:text-novartis-font', {
          'text-brand': !printMode,
          'text-novartis-font': printMode,
        })}
      >
        <div className="text-4xl font-bold text-center [&_span]:text-2xl [&_span]:font-normal [&_span]:flex [&_span]:w-full [&_span]:justify-center">
          <RichText html={service.cardTitle} />
        </div>
      </div>
    </div>
  );
};

export default CardLeft;
