import React, { useContext } from "react";
import classnames from "classnames";
import context from "@nvs/gatsby-theme-leap-core/src/components/hoc/context/product/context";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

const IsiHeader: React.FC<{
	toggled: boolean;
	onClick: React.MouseEventHandler<HTMLButtonElement>;
}> = ({ toggled, onClick }) => {
	const ctx = useContext(context);
	return (
		<div className='w-full flex items-center h-11 bg-gradient-to-b from-brand-secondary to-brand text-base lg:text-lg [&_p]:text-font-contrast font-semibold mb-4 pl-3'>
			<div className='w-full mx-auto max-w-6xl mx-auto flex justify-between justify-center items-center'>
				<p>
					{ctx.json.pages.footer.isi.headerTitle}
				</p>
				<button onClick={onClick} aria-label={ctx.json.pages.footer.isi.headerTitle}>
					<div className='flex items-center py-1 px-3 focus:outline-none'>
						<p className="hidden lg:block">
							{toggled
								? ctx.json.pages.footer.isi.viewLess
								: ctx.json.pages.footer.isi.viewMore}
						</p>
						<div className='pl-2'>
							<ChevronDownIcon
								aria-hidden='true'
								className={classnames("h-5 fill-white", {
									"rotate-180": !toggled,
								})}
							/>
						</div>
					</div>
				</button>
			</div>
		</div>
	);
};

export default IsiHeader;
