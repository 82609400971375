import React from "react";
import { WindowLocation, useLocation } from "@gatsbyjs/reach-router";
import {
  BrandHeader,
  EnrollmentHeader,
} from "../../../../../../components/header";
import {
  basePath,
  homePath,
} from "@nvs/gatsby-theme-leap-core/src/components/domains/flow/flow";
import { routes as confirmationRoutes } from "@nvs/gatsby-theme-leap-core/src/components/domains/confirmation/flow/confirmation-flow";
import { routes as enrollmentRoutes } from "@nvs/gatsby-theme-leap-core/src/components/domains/flow/enrollment-flow";

export const isBrandHeader = (location: WindowLocation) => {
  return (
    location?.pathname === homePath ||
    location?.pathname.includes(
      basePath +
        "/" +
        enrollmentRoutes.confirmation +
        "/" +
        confirmationRoutes.confirmed
    )
  );
};

export const Header: React.FC = () => {
  const location = useLocation();
  return (
    <>{isBrandHeader(location) ? <BrandHeader /> : <EnrollmentHeader />}</>
  );
};

export default Header;
