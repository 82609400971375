import React from "react";
import Indications from "@nvs/gatsby-theme-leap-core/src/components/hoc/components/isi/indications";
import SafetyInformationTop from "./safety-information-top";
import SafetyInformationBottom from "@nvs/gatsby-theme-leap-core/src/components/hoc/components/isi/safety-information-bottom";

const IsiBody: React.FC<{}> = () => {
  return (
    <div className="pb-11 pt-1 px-6">
      <div className="flex flex-col justify-center lg:flex-row lg:justify-between mx-auto max-w-6xl lg:gap-x-20">
        <div className="flex-2">
          <SafetyInformationTop />
        </div>
        <div className="flex-1 hidden lg:block">
          <Indications />
        </div>
      </div>
      <div className="mt-5 flex flex-col justify-center mx-auto max-w-6xl">
        <SafetyInformationBottom />
        <div className="indications-mobile mt-11 lg:hidden">
          <Indications />
        </div>
      </div>
    </div>
  );
};

export default IsiBody;
