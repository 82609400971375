import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import MDXRenderer from "@nvs/gatsby-theme-leap-core/src/components/hoc/components/mdx/mdx-renderer";

export const SafetyInformationTop: React.FC<{}> = () => {
  const safetyInformation = useStaticQuery(
    graphql`
      {
        mdx(
          internal: {
            contentFilePath: { regex: "/isi/safety-information-top/" }
          }
        ) {
          body
        }
      }
    `
  );

  if (!safetyInformation.mdx) {
    console.warn("ISI safety information top content not configured.");
  }

  return (
    <>
      {safetyInformation.mdx && (
        <div className="prose max-w-xl prose-h3:text-brand prose-h3:text-base text-base">
          <MDXRenderer>{safetyInformation.mdx.body}</MDXRenderer>
        </div>
      )}
    </>
  );
};

export default SafetyInformationTop;
