import React from "react";
import sneakerIcon from "../../../../../assets/images/sneaker.svg";
import mealIcon from "../../../../../assets/images/meal.svg";
import activityIcon from "../../../../../assets/images/activity.svg";
import doctorIcon from "../../../../../assets/images/doctor.svg";
import medicineTrackerIcon from "../../../../../assets/images/medicine_tracker.svg";
import trackingIcon from "../../../../../assets/images/tracking.svg";
import supportIcon from "../../../../../assets/images/support.svg";

type ImageFactoryProps = {
  iconKey: string;
};

export type ImageMappingType = {
  live: string;
  tools: string;
  dietary: string;
  tracking: string;
  symptoms: string;
  tips: string;
  lifestyleNotcpa: string;
  trackingNotcpa: string;
  symptomsNotcpa: string;
};

export const supportImageMapping: ImageMappingType = {
  live: supportIcon,
  tools: medicineTrackerIcon,
  dietary: mealIcon,
  tracking: activityIcon,
  symptoms: trackingIcon,
  tips: doctorIcon,
  lifestyleNotcpa: sneakerIcon,
  trackingNotcpa: activityIcon,
  symptomsNotcpa: trackingIcon,
};

// StaticImage can't take a prop in the src field so leaving this component as is for now
export const SupportImageFactory = ({ iconKey }: ImageFactoryProps) => {
  const getImageSrc = (key: string) => {
    if (Object.keys(supportImageMapping).includes(key)) {
      return supportImageMapping[key as keyof ImageMappingType];
    } else {
      return undefined;
    }
  };
  return <img className="w-8" src={getImageSrc(iconKey)} alt={iconKey} />;
};
