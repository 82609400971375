import React, { useContext } from "react";
import { navigate } from "gatsby";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import context from "@nvs/gatsby-theme-leap-core/src/components/hoc/context/product/context";
import Button from "@nvs/gatsby-theme-leap-core/src/components/hoc/components/button";

const BackButtonLink = () => {
  const ctxt = useContext(context);

  return (
    <div className="w-full">
      <Button
        onClick={() => navigate(-1)}
        className="cursor-pointer text-lg font-bold text-brand flex items-center gap-1"
      >
        <ChevronLeftIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
        {ctxt.json.pages.questions.back_button_label}
      </Button>
    </div>
  );
};

export default BackButtonLink;
