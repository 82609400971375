import React, { useContext } from "react";
import { enrollmentContext } from "@nvs/gatsby-theme-leap-core/src/components/hoc/context/enrollment/enrollment-context";
import context from "@nvs/gatsby-theme-leap-core/src/components/hoc/context/product/context";
import { useLocation } from "@gatsbyjs/reach-router";
import BackButtonLink from "./back-button";

const BackButton = () => {
  const ctxt = useContext(context);
  const enrollmentCtxt = useContext(enrollmentContext);

  const { pathname } = useLocation();
  if (enrollmentCtxt.isFastTrack(ctxt)) {
    if (pathname !== "/enrollment/wizard/performer/1/") {
      return <BackButtonLink />;
    } else {
      return <></>;
    }
  } else {
    return <BackButtonLink />;
  }
};

export default BackButton;
