import React from "react";
import { RichText } from "@nvs/gatsby-theme-leap-core/src/components/hoc/components";
import { isBrandHeader } from "../../../@nvs/gatsby-theme-leap-core/components/hoc/components/header/header";

const MenuSection: React.FC<{ options: string[] }> = ({ options }) => {
  return (
    <div
      className={
        isBrandHeader()
          ? "flex items-center divide-x divide-font"
          : "flex items-center divide-x-2 lg:divide-brand lg:divide-opacity-30 divide-font"
      }
    >
      {options.map((option, i) => (
        <span key={option} className="px-2">
          <RichText html={option} />
        </span>
      ))}
    </div>
  );
};

export default MenuSection;
