import React, { useContext } from "react";
import context from "@nvs/gatsby-theme-leap-core/src/components/hoc/context/product/context";
import BrandLogo from "@nvs/gatsby-theme-leap-core/src/components/hoc/components/logo/logo";
import PhoneCall from "./phone-call";
import Menu from "../common/menu";
import EnrollmentHeaderMobile from "./enrollment-header-mobile";
import { Link } from "@nvs/gatsby-theme-leap-core/src/components/hoc/components/link";

const EnrollmentHeader: React.FC = () => {
  const ctxt = useContext(context);

  return (
    <>
      <EnrollmentHeaderMobile />
      <header className="w-full flex flex-col shadow-md hidden lg:block print:hidden">
        <div className="max-w-6xl w-full lg:mx-auto lg:py-5 lg:px-2 flex flex-1 items-center justify-between text-brand text-xs">
          <div className="flex-1 justify-left">
            <Link
              href="https://www.entresto.com"
              target="_blank"
              rel="noopener noreferrer"
              title="Entresto Logo"
            >
              <BrandLogo className="w-full h-16 [max-width:160px] [min-width:160px] pr-5" />
            </Link>
          </div>
          <div className="flex items-center justify-end">
            <div>
              <Menu />
            </div>
            {ctxt.json.params.phoneNumber && (
              <div className="flex flex-col items-center text-xl pl-2 py-0">
                <PhoneCall
                  label={
                    ctxt.json.params.brandPhoneDisplay ||
                    ctxt.json.params.phoneNumber
                  }
                  phoneNumber={ctxt.json.params.phoneNumber}
                  callCtaText={ctxt.json.pages.header.callCtaText}
                />
              </div>
            )}
          </div>
        </div>
      </header>
    </>
  );
};

export default EnrollmentHeader;
