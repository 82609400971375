import React from "react";
import { useContext } from "react";
import { window } from "browser-monads";
import { FlowRouteProps } from "@nvs/gatsby-theme-leap-core/src/components/domains/flow/flow";
import context from "@nvs/gatsby-theme-leap-core/src/components/hoc/context/product/context";
import { enrollmentContext } from "@nvs/gatsby-theme-leap-core/src/components/hoc/context/enrollment/enrollment-context";
import { useLocation } from "@gatsbyjs/reach-router";
import {
  ConsentIDs,
  ServiceNames,
} from "@nvs/gatsby-theme-leap-core/src/components/hoc/constants/service.constants";
import { EnrollmentLayout } from "@nvs/gatsby-theme-leap-core/src/components/hoc/components/layout/enrollment-layout";
import Section from "@nvs/gatsby-theme-leap-core/src/components/hoc/components/section/section";
import {
  ConsentInfo,
  ContactInfo,
  PersonalInfo,
  ServicesInfo,
} from "@nvs/gatsby-theme-leap-core/src/components/domains/forms/review/components";
import Button from "@nvs/gatsby-theme-leap-core/src/components/hoc/components/button";
import { UserActionType } from "@nvs/gatsby-theme-leap-core/src/components/hoc/components/analytics";
import {
  EventAction,
  EventCategory,
  EventKeys,
  EventLabel,
  EventValues,
} from "@nvs/gatsby-theme-leap-core/src/services/ga-enums-interfaces";
import { PencilEditButtonIcon } from "@nvs/gatsby-theme-leap-core/src/assets/images/pencil-edit-button";
import { RichText } from "@nvs/gatsby-theme-leap-core/src/components/hoc/components";

const Review: React.FC<FlowRouteProps> = ({ location, flow }) => {
  const ctxt = useContext(context);
  const enrollmentCtxt = useContext(enrollmentContext);
  const { href } = useLocation();
  const enrolledServices = ctxt.json.services.filter((service) =>
    service.isEligible(
      enrollmentCtxt.input.performer,
      enrollmentCtxt.input.questions,
      enrollmentCtxt.input.consents,
      enrollmentCtxt.input.discardedServices
    )
  );
  const submit = () => {
    flow.next(location!);
  };

  return (
    <EnrollmentLayout
      label={ctxt.json.pages.review.label}
      mlrVersion={ctxt.json.pages.review.mlrVersion}
      title={ctxt.json.pages.review.header}
    >
      <div className="text-font">
        <Section
          width="max-w-2xl w-full"
          title={ctxt.json.pages.review.description}
          titleClassName="text-2xl text-left"
        >
          <div className="text-left text-lg w-full">
            <ServicesInfo />
          </div>
          <section className="gap-12 px-4 py-8 lg:py-12 lg:px-24 rounded-2xl shadow-leap w-full">
            <header className="flex justify-between items-center">
              <h3 className="text-2xl">{ctxt.json.pages.review.infoTitle}</h3>
              <Button
                onClick={() => {
                  window.history.back();
                }}
                analytics={{
                  userActionType: UserActionType.click,
                  eventArgs: {
                    [EventKeys.PageRequestTime]: new Date().getTime(),
                    [EventKeys.EventCategory]: EventCategory.Review,
                    [EventKeys.EventAction]: EventAction.Edit,
                    [EventKeys.EventLabel]: EventLabel.Profile,
                    [EventKeys.ReviewAction]: EventAction.Edit,
                    [EventKeys.Event]: EventValues.GAEvent,
                    [EventKeys.PageURL]: href,
                  },
                }}
                className="flex bg-neutral w-fit px-6 py-3 rounded-3xl cursor-pointer text-lg font-bold text-brand items-center"
              >
                <PencilEditButtonIcon className="whitespace-nowrap inline fill-brand" />
                <span className="ml-2 tracking-wider">
                  {ctxt.json.pages.review.editProfile}
                </span>
              </Button>
            </header>
            <div className="flex flex-col divide-y gap-8 divide-neutral-200">
              <div className="pt-8">
                <PersonalInfo />
              </div>
              <div className="pt-8">
                <ContactInfo />
              </div>
            </div>
          </section>
          <section className="flex flex-col gap-y-5">
            <RichText html={ctxt.json.pages.review.legal} />
            {enrolledServices.map(
              (service) => service.legal && <RichText html={service.legal} />
            )}
          </section>
          <div id="review-anchor" className="flex mx-auto">
            <Button
              analytics={{
                userActionType: UserActionType.click,
                eventArgs: {
                  [EventKeys.PageRequestTime]: new Date().getTime(),
                  [EventKeys.EventCategory]: EventCategory.Review,
                  [EventKeys.EventAction]: EventAction.SignUp,
                  [EventKeys.EventLabel]: EventLabel.Click,
                  [EventKeys.ReviewAction]: EventAction.SignUp,
                  [EventKeys.Event]: EventValues.GAEvent,
                },
              }}
              onClick={submit}
            >
              {ctxt.json.pages.review.signupLabel}
            </Button>
          </div>
        </Section>
      </div>
    </EnrollmentLayout>
  );
};
export default Review;
