import moment from "moment";
import { getAgeFromDOB } from "@nvs/gatsby-theme-leap-core/src/components/hoc/components/inputs/common-functions";
import { AgeConstants } from "@nvs/gatsby-theme-leap-core/src/components/hoc/constants/form.constants";
import { ContextService } from "@nvs/gatsby-theme-leap-core/src/components/hoc/context/product/context";
import { EnrollmentContextService } from "@nvs/gatsby-theme-leap-core/src/components/hoc/context/enrollment/enrollment-context";

const dobLength = 10;
const infantAge = 1;

export function dobValidation(
  value: string | undefined,
  ctxt: ContextService,
  enrollmentCtxt: EnrollmentContextService
) {
  if (
    !value ||
    value.trim().length < dobLength ||
    !moment(value, "YYYY-MM-DD", true).isValid()
  ) {
    return ctxt.json.getField("dob")?.errors?.required;
  }
  const userEntered = moment(value + "T00:00:00");
  const today = moment();
  today.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const age = getAgeFromDOB(value as string);
  const diff = today.diff(userEntered, "days");
  const validateAdultPatient = () => {
    if (diff < 0) {
      return ctxt.json.getField("dob")?.errors?.adultFuture;
    } else if (age < AgeConstants.adult) {
      return ctxt.json.getField("dob")?.errors?.over;
    }
    return true;
  };
  const validatePediatricPatient = () => {
    if (age >= AgeConstants.adult) {
      return ctxt.json.getField("dob")?.errors?.under;
    } else if (diff < 0) {
      return ctxt.json.getField("dob")?.errors?.pediatricFuture;
    } else if (age < infantAge) {
      return ctxt.json.getField("dob")?.errors?.infant;
    }
    return true;
  };
  if (age > AgeConstants.maxAge) {
    return ctxt.json.getField("dob")?.errors?.mismatch;
  }
  const birthDateQuestion = enrollmentCtxt.getQuestion("birthDate");
  if (birthDateQuestion) {
    return birthDateQuestion.answer
      ? validateAdultPatient()
      : validatePediatricPatient();
  }
  return true;
}
