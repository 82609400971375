import React, { useContext } from "react";
import MenuSection from "./section";
import context from "@nvs/gatsby-theme-leap-core/src/components/hoc/context/product/context";
import { isBrandHeader } from "../../../@nvs/gatsby-theme-leap-core/components/hoc/components/header/header";
import { useLocation } from "@gatsbyjs/reach-router";
import { useStaticQuery } from "gatsby";

const Menu: React.FC = () => {
  const ctx = useContext(context);
  const location = useLocation();

  const config = () => {
    const brandHeaderConfig = [
      [
        ctx.json.pages.header.menu.prescribing,
        ctx.json.pages.header.menu.prescribing_patient,
      ],
      [
        ctx.json.pages.header.menu.for_non_us_residents,
        ctx.json.pages.header.menu.healthcare,
      ],
      [ctx.json.pages.header.menu.spanish_language],
    ];
    const enrollmentHeaderConfig = [
      [
        ctx.json.pages.header.menu.prescribing_enrollment,
        ctx.json.pages.header.menu.prescribing_patient_enrollment,
      ],
      [
        ctx.json.pages.header.menu.for_non_us_residents_enrollment,
        ctx.json.pages.header.menu.healthcare_enrollment,
      ],
      [],
    ];
    return isBrandHeader(location) ? brandHeaderConfig : enrollmentHeaderConfig;
  };

  return (
    <div
      className={
        isBrandHeader(location)
          ? "flex gap-5 [@media(min-width:1500px)]:gap-2 [@media(min-width:1518px)]:gap-5"
          : "flex gap-5"
      }
    >
      <MenuSection options={config()[0]} />
      <div className="flex gap-2 items-center">
        <div>{ctx.json.pages.header.usOnly}</div>
        <MenuSection options={config()[1]} />
        <MenuSection options={config()[2]} />
      </div>
    </div>
  );
};

export default Menu;