export * as Source from "./source";

export enum URISource {
  WEBSITE = "website",
  QR = "qr",
}

export const DEFAULT_SOURCE = URISource.WEBSITE;

const SOURCE_URI_KEY = "utm_medium";

export const SOURCE_ITEM_KEY = "source";

export const getSourceFromUri = (search: string): string | null => {
  const urlSource = new URLSearchParams(search);
  return urlSource.get(SOURCE_URI_KEY);
};

export const get = () => {
  const sessionSource = sessionStorage.getItem(SOURCE_ITEM_KEY);
  return sessionSource ?? DEFAULT_SOURCE;
};

export const set = (source: string) => {
  sessionStorage.setItem(SOURCE_ITEM_KEY, source);
};

export const isQRSource = (source: string | null): source is URISource.QR =>
  source?.includes(URISource.QR) ? true : false;

export const init = () => {
  const search = window.location.search;
  const source = getSourceFromUri(search);
  if (isQRSource(source)) {
    set(URISource.QR);
  }
};
