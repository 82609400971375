import React, { useContext, useMemo } from "react";
import RichText from "@nvs/gatsby-theme-leap-core/src/components/hoc/components/rich-text";
import { CardProps } from "@nvs/gatsby-theme-leap-core/src/components/domains/confirmation/components/services/card/index";
import context from "@nvs/gatsby-theme-leap-core/src/components/hoc/context/product/context";
import { enrollmentContext } from "@nvs/gatsby-theme-leap-core/src/components/hoc/context/enrollment/enrollment-context";
import { capitalize } from "@nvs/gatsby-theme-leap-core/src/components/hoc/components/inputs/common-functions";
import programLogo from "../../../../../../../../assets/images/enspire_program.svg";
import { ServiceStatus } from "@nvs/gatsby-theme-leap-core/src/components/hoc/constants/service.constants";
import { PerformerType } from "@nvs/gatsby-theme-leap-core/src/components/hoc/constants/performer.constants";
import { SupportImageFactory } from "../../../supportImage";

export const AdherenceCard = ({ status, service }: CardProps) => {
  const ctxt = useContext(context);
  const enrollmentCtxt = useContext(enrollmentContext);

  const displayName = useMemo(() => {
    // TODO: this statement is useless
    if (!enrollmentCtxt) return "";
    const nm = enrollmentCtxt.getFormField("firstname");
    if (!nm) return "";
    return capitalize(nm.value);
  }, [enrollmentCtxt]);

  const adherenceDeclineItems = ctxt.json.pages.confirmation.adherenceDecline;
  const adherenceDeclineLabels: string[] = Object.values(adherenceDeclineItems);

  return (
    <div className="flex justify-center">
      <div className="text-center bg-white border-1 border-white shadow-leap rounded-2xl">
        {(status === "confirmed" || status === "enrolled") && (
          <div className="w-full text-left py-16 lg:px-24 px-6 space-y-0 items-center flex flex-col lg:gap-12 gap-8">
            <div className="w-full flex justify-center">
              <img src={programLogo} className="w-48" alt="" />
            </div>
            <div>
              <div className="flex flex-col gap-5">
                  <RichText
                    html={`${ctxt.json.pages.confirmation.confirmationDescriptionNotcpa.replaceAll(
                      "{name}",
                      displayName
                    )}`}
                  />
              </div>
              <div className="grid gap-x-12 gap-y-7 lg:grid-cols-2 text-brand pt-9">
                {adherenceDeclineLabels?.map(
                  (adherenceLabel: string, index: number) => (
                    <div key={adherenceLabel} className="flex-row flex items-center gap-4">
                      <SupportImageFactory
                        iconKey={
                          Object.keys(adherenceDeclineItems)[index]
                        }
                      />
                      <b>
                        <RichText html={`${adherenceLabel}`} />
                      </b>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        )}
        {status === "denied" &&
          enrollmentCtxt.input.performer === PerformerType.CAREGIVER && (
            <div className="flex justify-center py-10">
              <div className="md:px-28 mx-4">
                <h1 className="text-primary">
                  {ctxt.json.pages.confirmation.ineligibleTitleAdherence}
                </h1>
                <p className="mt-4 font-bold">
                  <RichText
                    html={`${ctxt.json.pages.confirmation.ineligibleDescriptionAdherence.replaceAll(
                      "{name}",
                      displayName
                    )}`}
                  />
                </p>
                <div className="flex justify-center">
                  <div className="md:w-64 w-80 h-px bg-gray-300 mt-4"></div>
                </div>
                <div className="text-left mt-8">
                  <RichText
                    html={`${ctxt.json.pages.confirmation.ineligibleNextStepsAdherence.replaceAll(
                      "{name}",
                      displayName
                    )}`}
                  />
                </div>
              </div>
            </div>
          )}
        {status === "duplicate" && (
          <div className="flex justify-center py-10">
            <div className="md:px-28 mx-4">
              <h1 className="text-primary">
                {ctxt.json.pages.confirmation.ineligibleTitleAdherence}
              </h1>
              <div className="mt-4">
                <b>
                  <RichText
                    html={`${ctxt.json.pages.confirmation.dupeDescriptionAdherence.replaceAll(
                      "{name}",
                      displayName
                    )}`}
                  />
                </b>
              </div>
              <div className="flex justify-center">
                <div className="md:w-48 w-80 h-px bg-gray-300 mt-4"></div>
              </div>
              <div className="text-left mt-8">
                <RichText
                  html={`${ctxt.json.pages.confirmation.dupeNextStepsAdherence.replaceAll(
                    "{name}",
                    displayName
                  )}`}
                />
              </div>
            </div>
          </div>
        )}
        {(status === ServiceStatus.error ||
          status === ServiceStatus.timeout) && (
          <div className="flex justify-center py-10">
            <div className="md:px-28 mx-4">
              <h1 className="text-primary">
                {ctxt.json.pages.confirmation.ineligibleTitleAdherence}
              </h1>
              <div className="mt-4">
                <b>{ctxt.json.pages.confirmation.errorDescriptionAdherence}</b>
              </div>
              <div className="flex justify-center">
                <div className="md:w-48 w-64 h-px bg-gray-300 mt-4"></div>
              </div>
              <div className="text-left mt-8">
                <RichText
                  html={`${ctxt.json.pages.confirmation.errorNextStepsAdherence.replaceAll(
                    "{name}",
                    displayName
                  )}`}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdherenceCard;