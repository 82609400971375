import React, { ReactNode } from 'react';

const IsiContainer: React.FC<{ children: ReactNode }> = ({children}) => {
	return (
        <div className="bg-[#E4E4E4] shadow-leap [&_li]:text-brand [&_strong]:text-brand [&_p]:text-brand [&_li::marker]:text-brand">
            {children}
        </div>
	);
};

export default IsiContainer;