import React, { useContext, useCallback, useRef } from "react";
import { Control, Controller, UseFormMethods } from "react-hook-form";
import {
	FormUpdater,
	SelectBox,
	TextBox,
} from "@nvs/gatsby-theme-leap-core/src/components/hoc/components/inputs";
import context from "@nvs/gatsby-theme-leap-core/src/components/hoc/context/product/context";
import AnalyticsFactory from "@nvs/gatsby-theme-leap-core/src/services/analytics-factory";
import {
	EventAction,
	EventCategory,
} from "@nvs/gatsby-theme-leap-core/src/services/ga";
import { AddressQuickFill } from "@nvs/gatsby-theme-leap-core/src/components/domains/forms/components/addressQuickFill/addressQuickFill.component";
import { sendGaFocusEvent } from "@nvs/gatsby-theme-leap-core/src/components/domains/forms/patient/enrollment-ga-events";
import RichText from "@nvs/gatsby-theme-leap-core/src/components/hoc/components/rich-text";
import { graphql, navigate, useStaticQuery } from "gatsby";

export const ContactInformation: React.FC<{
	hookProps: {
		errors: UseFormMethods["errors"];
		register: UseFormMethods["register"];
		formState: UseFormMethods["formState"];
	};
	control: Control;
	setValue: UseFormMethods["setValue"];
	suffix?: string;
	clearErrors: UseFormMethods["clearErrors"];
}> = ({ hookProps, control, setValue, suffix = "", clearErrors }) => {
	const ctxt = useContext(context);
	const { site } = useStaticQuery(query);
	const aptMaxLength = 50;
	const cityMaxLength = 30;
	const zipMaxLength = 5;
	const inputCityRef = useRef(null);
	const inputZipRef = useRef(null);
	const analytics = AnalyticsFactory.createAnalytics("ga");
	const sendGaChangeEvent = useCallback((name: string): void => {
		analytics.sendSimpleEvent(
			EventCategory.ProfileForm,
			EventAction.FieldChange,
			name
		);
	}, []);

	const onUpdateAddress = () => {
		(
			inputCityRef.current as unknown as { updateShowReset: Function }
		)?.updateShowReset(true);
		(
			inputZipRef.current as unknown as { updateShowReset: Function }
		)?.updateShowReset(true);
	};

	return (
		<>
			{site.siteMetadata.experience === "support" && (
				<div className='flex flex-col mb-12'>
					<RichText html={ctxt.json.pages.userProfile.formAdressDisclaimer} />
				</div>
			)}
			<div className='flex flex-col md:flex-row gap-5'>
				<div className='w-full'>
					<AddressQuickFill
						label={ctxt.json.getField("mail" + suffix)?.label}
						name={"mail" + suffix}
						{...hookProps}
						setValue={setValue}
						suffix={suffix}
						onChange={() => {
							clearErrors("mail" + suffix);
						}}
						onUpdate={onUpdateAddress}
					/>
					<FormUpdater control={control} controlName={"mail" + suffix} />
				</div>
				<div className='w-full'>
					<TextBox
						label={ctxt.json.getField("apt" + suffix)?.label}
						name={"apt" + suffix}
						maxLength={aptMaxLength}
						{...hookProps}
						setValue={setValue}
						onChange={() => clearErrors("apt" + suffix)}
					/>
					<FormUpdater control={control} controlName={"apt" + suffix} />
				</div>
			</div>
			<div className='flex flex-col md:flex-row gap-5 mt-5'>
				<div className='w-full'>
					<TextBox
						label={ctxt.json.getField("city" + suffix)?.label}
						name={"city" + suffix}
						maxLength={cityMaxLength}
						ref={inputCityRef}
						setValue={setValue}
						{...hookProps}
						onChange={() => clearErrors("city" + suffix)}
					/>
					<FormUpdater control={control} controlName={"city" + suffix} />
				</div>
				<div className='w-full'>
					<Controller
						control={control}
						name={"state" + suffix}
						render={({ onChange, value }) => (
							<SelectBox
								className='w-full'
								value={value}
								label={ctxt.json.getField("state" + suffix)?.label}
								name={"state" + suffix}
								options={ctxt.json.getField("state" + suffix)?.options}
								placeholder={"Select State"}
								{...hookProps}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									if (onChange) {
										onChange(e);
									}
									sendGaChangeEvent("state" + suffix);
									clearErrors("state" + suffix);
								}}
								onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
									//TODO: Use analytics props and remove onFieldFocus
									sendGaFocusEvent(event.target.name);
								}}
							/>
						)}
					/>
					<FormUpdater control={control} controlName={"state" + suffix} />
				</div>
				<div className='w-44 flex-none'>
					<TextBox
						label={ctxt.json.getField("zip" + suffix)?.label}
						name={"zip" + suffix}
						maxLength={zipMaxLength}
						ref={inputZipRef}
						placeholder={"xxxxx"}
						{...hookProps}
						setValue={setValue}
						onChange={() => clearErrors("zip" + suffix)}
					/>
					<FormUpdater control={control} controlName={"zip" + suffix} />
				</div>
			</div>
		</>
	);
};

export default ContactInformation;

const query = graphql`
    query experienceQuery {
        site {
            siteMetadata {
                experience
            }
        }
    }
`;
