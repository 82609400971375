import React , { useContext } from 'react';
import { enrollmentContext } from "@nvs/gatsby-theme-leap-core/src/components/hoc/context/enrollment/enrollment-context";
import context from "@nvs/gatsby-theme-leap-core/src/components/hoc/context/product/context";
import RichText from '@nvs/gatsby-theme-leap-core/src/components/hoc/components/rich-text/index';
import InformationPanel from '@nvs/gatsby-theme-leap-core/src/components/hoc/components/information-panel/index';
import Section from '@nvs/gatsby-theme-leap-core/src/components/hoc/components/section/section';

//shadowed this npaf component part of this story : LEAP-129877
export const NpafFooter = () => {
    const enrollmentCtxt = useContext(enrollmentContext);
	const ctxt = useContext(context);
    const isPrivateInsurance =
    enrollmentCtxt.getQuestion('insurance_type')?.answer === 'private';

	return (
		<>
		{!isPrivateInsurance ? (
			<Section
				title={ctxt.json.pages.confirmation.npaf.title}
				description={ctxt.json.pages.confirmation.npaf.description}
				width="max-w-4xl"
			>
				<InformationPanel>
					<RichText html={ctxt.json.pages.confirmation.npaf.contact} />
				</InformationPanel>
			</Section>
		) : (
			<></>
		)}
	</>
	);
};

export default NpafFooter;
